import React from 'react';

import {Container} from 'react-bootstrap';

export default function Games (props) {
    return (
        <div className="App-body">
            <div className="about">
                <Container>
                    Games will be developed and announced here
                </Container>
            </div>
        </div>
    );
}