import React from "react";

import { Container } from "react-bootstrap";

export default function About(props) {
  return (
    <div className="App-body">
      <div className="about">
        <Container>Copyright 2020-2022 All Rights Reserved.</Container>
      </div>
    </div>
  );
}
