import React, { Component } from "react";
import axios from "axios";

import { ReCaptcha } from "react-recaptcha-google";

import { Container, Form, Button, Accordion, Card } from "react-bootstrap";

import ButtonMailto from "../ButtonMailto";

export default class Support extends Component {
  constructor(props, context) {
    super(props, context);
    this.validMail = this.validMail.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      name: "",
      email: "",
      message: "",
    };
  }

  validMail() {
    const email = this.state.email;
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  componentDidMount() {
    if (this.captcha) {
      console.log("started, just a second...");
      this.captcha.reset();
      this.captcha.execute();
    }
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  onSubmit(event) {
    event.preventDefault();

    if (this.state.name === "") {
      alert("No name specified, please enter one");
      return;
    }
    if (!this.validMail()) {
      alert("Invalid email, please re-enter and resend");
      return;
    }
    if (this.state.message === "") {
      alert("No question found, please ask one");
      return;
    }

    // Actually send the form
    axios({
      method: "POST",
      url: "/send",
      data: this.state,
    }).then((response) => {
      if (response.data.status === "success") {
        alert("Message Sent.");
        this.resetForm();
      } else if (response.data.status === "fail") {
        alert("Message failed to send.");
      }
    });
  }

  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset();
      this.captcha.execute();
    }
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token");
  }

  resetForm() {
    this.setState({ name: "", email: "", message: "" });
  }

  render() {
    return (
      <div className="App-body">
        <div className="about">
          <Container>
            <ButtonMailto
              label="Send Support Ticket"
              mailto="mailto:support@huskerbeach.awsapps.com"
            />
          </Container>
        </div>
      </div>
    );
  }
}
