import React from "react";

import { Container, Row, Image } from "react-bootstrap";

import predictive_character_seeking_unity_sm from "../Images/Unity/predictive-character-seeking-sm.jpg";
import predictive_character_seeking_unity_lg from "../Images/Unity/predictive-character-seeking-lg.jpg";

export default function Home(props) {
  return (
    <div className="App-body">
      <div className="about">
        <Container>
          <Row>
            <a
              href="https://assetstore.unity.com/packages/tools/ai/predictive-character-seeking-185419"
              target="_blank"
            >
              <Image
                src={predictive_character_seeking_unity_sm}
                className="d-inline-block align-top"
                alt="Predictive Character Seeking"
                rounded
              />
            </a>
          </Row>
        </Container>
      </div>
    </div>
  );
}
